<template>
  <div>
    <v-row>
      <v-col cols="4" v-for="(child, i) in final_routes" :key="i">
        <v-card class="rounded-lg" :to="`/settings/${child.route}`">
          <v-card-text class="text-center">
            <div>
              <v-icon color="primary" style="font-size: 80px">{{
                child.icon
              }}</v-icon>
            </div>
            <h2 class="mt-4">
              {{ child.title }}
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "settingsNav",
  data() {
    return {
      children: [
        {
          icon: "public",
          title: "כללי",
          route: "general",
          premission: "settings access",
        },
        {
          icon: "notifications",
          title: "עדכונים",
          route: "notifications",
          premission: "notifications_settings access",
        },
        {
          icon: "receipt_long",
          title: "חשבוניות",
          route: "invoices",
          premission: "invoices_settings access",
        },
        {
          icon: "payments",
          title: " אמצעי תשלום",
          route: "payments",
          premission: "payments_settings access",
        },
        {
          icon: "tune",
          title: "הגדרות מתקדמת",
          route: "advanced",
          premission: "advanced_settings access",
        },
      ],
    };
  },

  computed: {
    final_routes() {
      const links = [];
      this.children.forEach((link) => {
        if (this.$store.getters.permission(link.premission)) {
          links.push(link);
        }
      });

      return links;
    },
  },
};
</script>
